import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { GlobalContext } from "../../context/GlobalContext"
import { useParams } from "react-router-dom";

const Registrasi = () => {

    let { idData } = useParams();
    console.log("ID Data")
    console.log( idData ) ;   

  const {state, handleFunction} = useContext(GlobalContext)

  let {
    data,
    setData,
    input,
    setInput,
    fetchStatus,
    setFetchStatus,
    currentId,
    setCurrentID
  } = state

let  {
  handleChangeRegistrasi,
  handleSubmitRegistrasi,
  handleDelete,
  handleEdit
} = handleFunction

useEffect(() => {

        setInput(
            {

                name : "",
                image_url : "",
                email : "",
                password : ""

            })

}, []);


  return (
    <>

    <div className="container mx-auto mt-10">
        <h1 className="mb-5 font-bold">FORM REGISTRASI</h1>
        <form onSubmit={handleSubmitRegistrasi}>
            <div className="mb-6">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nama</label>
                <input onChange={handleChangeRegistrasi} type="text" value={input.name} name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="image_url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Foto</label>
                <input onChange={handleChangeRegistrasi} type="text" value={input.image_url} name="image_url" id="image_url" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                <input onChange={handleChangeRegistrasi} type="text" value={input.email} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>

            <div className="mb-6">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password</label>
                <input onChange={handleChangeRegistrasi} type="text" value={input.password} name="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
    </div>

    </>
  )
}

export default Registrasi