import React from 'react'
import Footer from '../components/Footer'
import Navigation from '../components/Navigations'


const Layout = (props) => {
  return (
    <>
        
            <Navigation />
            
            <div className="container mx-auto mt-5">
                {props.children}
            </div>
            <Footer />
        
    </>
  )
}

export default Layout