import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const DetailData = () => {
  let { idData }  = useParams()
  const [ data, setData ] = useState(null)

  useEffect(() => {
    if(idData !== undefined){
        axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy/${idData}`)
        .then((res) => {
            console.log(res)
            setData(res.data)
        })
    }

  },[])

  console.log(data)

  function formatRupiah(angka, prefix){
    var number_string = angka.replace(/[^,\d]/g, '').toString(),
    split   		= number_string.split(','),
    sisa     		= split[0].length % 3,
    rupiah     		= split[0].substr(0, sisa),
    ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if(ribuan){
        let separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}
  
  return (
    <div>
        {
            data !== null &&
            <>


                <div className="container mx-auto mt-10">
        <h1 className="mb-5 font-bold">DETAIL DATA LOWONGAN KERJA</h1>
        <div clasName="flex">
            <div className="mb-6">
            <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pekerjaan</label>
              {data?.title}
            </div>
            <div className="mb-6">
            <label htmlFor="job_description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Deskripsi</label>
              {data?.job_description}                
            </div>
            <div className="mb-6">
            <label htmlFor="job_qualification" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Kualifikasi</label>
            {data?.job_qualification}

            </div>

            <div className="mb-6">
            <label htmlFor="job_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tipe Pekerjaan</label>
            {data?.job_type}

            </div>
            <div className="mb-6">
            <label htmlFor="job_tenure" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tenure</label>
            {data?.job_tenure}

            </div>
            <div className="mb-6">
            <label htmlFor="job_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Status</label>
            {data?.job_status}
                
            </div>
            <h2 className="mb-2 font-bold">DATA PERUSAHAAN</h2>
            <div className="mb-6">
            <label htmlFor="company_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nama Perusahaan</label>
            {data?.company_name}
                
            </div>
            <div className="mb-6">
            <label htmlFor="company_city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Kota</label>
            {data?.company_city}
                
            </div>
            <div className="mb-6">
            <label htmlFor="company_image_url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Logo Perusahaan</label>
            <img class="w-20 h-auto rounded-lg" src={data?.company_image_url} />
                
            </div>
            <h2 className="mb-2 font-bold">DATA GAJI</h2>
            <div className="mb-6">
            <label htmlFor="salary_min" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gaji Minimal</label>
            { formatRupiah(data?.salary_min + "") }
                
            </div>
            <div className="mb-6">
            <label htmlFor="salary_max" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gaji Maksimal</label>
            { formatRupiah(data?.salary_max + "") }
            </div>
            </div>   
            
    </div>
            </>
        }
        
        
    </div>
  )
}

export default DetailData