import axios from 'axios'
import Cookies from 'js-cookie'
import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {

    const navigate = useNavigate()
    const [input, setInput] = useState(
        {
            email : "",
            password : ""
        }
    )

    const handleChange = (event) => { 
        //console.log(event)
        let name = event.target.name
        let value = event.target.value

        setInput({...input, [name] : value})
    }

    const handleLogin = (event) => {
        event.preventDefault()
        //console.log(input)

        let {email,password} = input

        axios.post(`https://dev-example.sanbercloud.com/api/login`, {email,password})
        .then((res) => {
            let data = res.data
            let {token, user} = data

            console.log(token)

            Cookies.set('token',token, { expires : 1 })
            Cookies.set('user',JSON.stringify(user), { expires : 1 })

            
            navigate('/dashboard')

        })
        .catch((err) => {
            alert(err)
            console.log(err)
        })

        setInput(
            {
                email : "",
                password : ""
            }
        )


        
        
    }

    return (
        <div className="flex items-center justify-center w-full h-screen">
            <form onSubmit={handleLogin} className="w-1/2">
                <div className="mb-6">
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                <input onChange={handleChange} name="email" type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@flowbite.com" required />
                </div>
                <div className="mb-6">
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your password</label>
                <input onChange={handleChange} name="password" type="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>

                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
            </form>
        </div>    
    )
}

export default Login