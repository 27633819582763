import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import LowonganPekerjaan from "../pages/lowonganPekerjaan/LowonganPekerjaan";
import Login from "../pages/login/Login";
import Dashboard from "../pages/dashboard/Dashboard";
import DetailData from "../pages/detailData/DetailData";
import ListData from "../pages/listData/ListData";
import Profil from "../pages/profil/Profil";
import FormInput from "../pages/formInput/FormInput";
import Registrasi from "../pages/registrasi/Registrasi";
import GantiPassword from "../pages/gantiPassword/GantiPassword";
import Layout from "../widget/Layout";
import LayoutDashboard from "../widget/LayoutDashboard";
import Cookies from "js-cookie";
import { Navigate } from 'react-router-dom';
import { GlobalProvider } from '../context/GlobalContext';


const Router = () => {

    const LoginRoute = (props) => {
        if(Cookies.get("token") === undefined ) {
            return props.children
        } else if(Cookies.get("token") !== undefined) {
            return <Navigate to={'/dashboard'} />
        }
    }

    const DashboardRoute = (props) => {
        if(Cookies.get("token") !== undefined ) {
            return props.children
        } else if(Cookies.get("token") === undefined) {
            return <Navigate to={'/'} />
        }
    }



    return(
        <BrowserRouter>

<GlobalProvider>

            <Routes>

                <Route path="/" element={
                    <Layout>
                        <Home />
                    </Layout>
                }/>

                <Route path="/detail-data/:idData" element={
                    <Layout>
                        <DetailData />
                    </Layout>
                }/>
                
                <Route path="/lowongan-pekerjaan" element={
                    <Layout>
                        <LowonganPekerjaan />
                    </Layout>
                }/>

                <Route path="/registrasi" element={
                    <Layout>
                        <Registrasi />
                    </Layout>
                }/>

                <Route path="/login" element={
                    <LoginRoute>
                        <Login />
                    </LoginRoute>
                }/>
                <Route path="/dashboard" element={
                    <DashboardRoute>
                        <LayoutDashboard>
                            <Dashboard />
                        </LayoutDashboard>
                    </DashboardRoute>
                }/>
                <Route path="/dashboard/list-job-vacancy" element={
                    <DashboardRoute>
                        <LayoutDashboard>
                            <ListData />
                        </LayoutDashboard>
                    </DashboardRoute>
                }/>
                <Route path="/dashboard/profil" element={
                    <DashboardRoute>
                        <LayoutDashboard>
                            <Profil />
                        </LayoutDashboard>
                    </DashboardRoute>
                }/>
                <Route path="/dashboard/change-password" element={
                    <DashboardRoute>
                        <LayoutDashboard>
                            <GantiPassword />
                        </LayoutDashboard>
                    </DashboardRoute>
                }/>

<Route path="/dashboard/form" element={
                    <DashboardRoute>
                        <LayoutDashboard>
                            <FormInput />
                        </LayoutDashboard>
                    </DashboardRoute>
                }/>

            <Route path="/dashboard/form/:idData" element={
                    <DashboardRoute>
                        <LayoutDashboard>
                            <FormInput />
                        </LayoutDashboard>
                    </DashboardRoute>
                }/>

            </Routes>
            </GlobalProvider>
        </BrowserRouter>
    )
}

export default Router