import axios from 'axios'
import React, { useEffect, useState } from 'react';
import Banner from '../../components/Banner'
import {Link} from 'react-router-dom'


const Home = () => {

    // Pengambilan Data Lowongan Pekerjaan
    const [data, setData] = useState(null)

    // Indikator
    const [fetchStatus, setFetchStatus] = useState(true)

    useEffect(() => {
        let fetchData = async () => {
            let result = await axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy`)
            let data = result.data.data
            setData(data)
        }
        
        if( fetchStatus ) { 
            fetchData()
            setFetchStatus(false)
        }
        
    },[])

    console.log(data)

    function formatRupiah(angka, prefix){
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if(ribuan){
            let separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const handleText = (param) => {
        if(param=== undefined) {
            return ""
        } else {
            return param.slice(0,200) + "..."
        }
    }
    
  return (
    <>
        
        <Banner />

        <div className="mt-10 bg-blue-300 rounded-md dark:bg-gray-800">
        <div className="z-20 w-full px-4 py-12 mx-auto lg:flex lg:items-center lg:justify-between sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-bold text-black dark:text-white sm:text-3xl">
            <span className="block">
              Sedang mencari pekerjaan, Mencari pekerjaan yang sesuai dengan minat.
            </span>
            <span className="block text-indigo-500">
              Registrasi sekarang, sebelum lowongan kerja nya diambil orang.
            </span>
          </h2>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow ">

              <button type="button" className="w-full px-6 py-4 text-base font-semibold text-center text-white transition duration-200 ease-in bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                <Link to="/registrasi">Registrasi</Link>
              </button>
            </div>
          </div>
        </div>
      </div>

        <div className="mt-10">
            <h1 className="pl-5 text-2xl font-bold lg:pl-0">Lowongan Pekerjaan</h1>
            <div className="flex flex-wrap items-start justify-center gap-10 mt-10 lg:justify-start">

                {
                    data !== null &&
                    data
                    .filter((res, index) => {
                        return index >= 0
                    })
                    
                    .map((res) => {

                        let desk01 = res.job_description;
                        let deskripsi = desk01.slice(0, 250) + '...';
                        
                        return(
   

<Link to={`/detail-data/${res.id}`} key={res.id} className="flex flex-col w-full max-w-xs p-6 overflow-hidden bg-white shadow-lg h-80 rounded-xl dark:bg-gray-800">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="flex items-center justify-start flex-grow w-full">
            <a href="#" className="relative block">
              <img alt="profil" src={res.company_image_url} className="object-cover w-10 h-10 mx-auto rounded-full " />
            </a>
            <div className="flex flex-col items-start ml-4">
              <span className="text-gray-700 dark:text-white">
                {res.company_name}
              </span>
              <span className="text-sm font-light text-gray-400 dark:text-gray-300">
                {res.company_city}
              </span>
            </div>
          </div>

        </div>
        <p className="mt-4 mb-2 text-lg text-gray-800 dark:text-white">
          {res.title}
        </p>
        <p className="text-sm font-normal text-gray-400 grow">
        {handleText(res.job_description)}
        </p>
        <div className="flex items-center justify-between p-2 my-6 bg-blue-100 rounded">
          <div className="flex items-start justify-between w-full">
            <p className="flex-grow w-full text-2xl text-gray-700">
              <span className="font-light text-gray-400 text-md">
                Rp.
              </span>
              { formatRupiah(res.salary_min + "") } 
              <span className="text-sm font-light text-gray-400">
                /Month
              </span>
            </p>
            <span className="flex-none px-3 py-1 text-sm text-indigo-500 border border-indigo-500 rounded-full">
            {res.job_tenure}
            </span>
          </div>
        </div>

      </Link>

                        )
                    })
                }    
            </div>
        </div>
    </>
  )
}

export default Home