import React from 'react';
import { Carousel } from 'flowbite-react';

const Banner = () => {
  return (
    <div className="container h-56 mx-auto mt-5 sm:h-64 xl:h-80 2xl:h-96" >
        <Carousel slideInterval={5000}>
            <img
            src="https://www.ipsos.com/sites/default/files/ct/news_and_polls/2021-07/man-at-work-unhappy.jpg"
            alt="..."
            />
            <img
            src="http://www.mitrakerja.com//assets/user/images/article/Work_Ethic_4.png"
            alt="..."
            />
            <img
            src="https://cohive.space/sojuhanjan/wp-content/uploads/2021/03/Ciri-Ciri-Perusahaan-yang-Memahami-Pentingnya-Team-Work-scaled-1200x675.jpg"
            alt="..."
            />
            <img
            src="https://businesspartnermagazine.com/wp-content/uploads/2021/03/Should-You-Return-To-Office-Work-Pros-And-Cons.jpg"
            alt="..."
            />
            <img
            src="https://www.karier.mu/blog/wp-content/uploads/2021/11/job-interview-191107-1.jpeg"
            alt="..."
            />
        </Carousel>
    </div>
  )
}

export default Banner