import React from 'react'

const Footer = () => {
  return (
    <footer className="container p-4 mx-auto mt-10 mb-5 bg-blue-600 rounded-lg md:px-6 md:py-8" >
  
        
        <span className="block text-sm text-white sm:text-center dark:text-gray-400 bg-blue">© 2022 SanberCode Project Akhir - Boyke Hendarsah . All Rights Reserved.
        </span>
    </footer>
  )
}

export default Footer