import Cookies from 'js-cookie'
import React from 'react'

const Profil = () => {


  var cuser  = JSON.parse(Cookies.get('user'));
  
  console.log("TIPE OF ... ");
  console.log(typeof cuser)
  console.log("USER ... ");
  console.log(cuser);
  console.log("EMAIL ... ");
  console.log(cuser.email);
  
  return (
    <>

<div className="container mx-auto mt-10">
  <h1 className="mb-5 font-bold">PROFIL</h1>
  <div clasName="flex">
    

    <div className="mb-6">
      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nama</label>
      {cuser.name}
    </div>

    <div className="mb-6">
      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
      {cuser.email}
    </div>

    <div className="mb-6">
      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Foto</label>
      <img alt="profil" src={cuser.image_url} className="object-cover w-40 h-auto rounded-5 " />
    </div>
    
  </div>   
</div>

    </>
  )
}

export default Profil