import Cookies from 'js-cookie'
import React, { createContext, useState } from "react";
import axios from 'axios'
import {useNavigate} from "react-router-dom"
export const GlobalContext = createContext()



export const GlobalProvider = (props) => {

  let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [input, setInput] = useState(
      {
        title : "",
        job_description : "",
        job_qualification : "",
        job_type : "",
        job_tenure : "",
        job_status : "",
        company_name : "",
        company_image_url : "",
        company_city : "",
        salary_min : "",
        salary_max : ""
      }
    )
  
    //indikator
    const [fetchStatus, setFetchStatus] = useState(true)
    const [currentId, setCurrentId] = useState(-1)
      
    const handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value

        //console.log(name)
        setInput({...input, [name] : value })
    }
      
    const handleSubmit = (param) => {
        param.preventDefault()
        let {title, job_description, job_qualification, job_type, job_tenure, job_status, company_name, company_image_url, company_city, salary_min, salary_max} = input
        console.log('Handle Submit')
        console.log(job_description)
        if (currentId === -1) {
          // Create Data
          console.log('Create Data Baru')
          let createData = async () => {
            try {
              console.log("cookies token")
              console.log(Cookies.get('token'))
              let res = await axios.post(`https://dev-example.sanbercloud.com/api/job-vacancy`,{title, job_description, job_qualification, job_type, job_tenure, job_status, company_name, company_image_url, company_city, salary_min, salary_max},
                {headers: {"Authorization" : "Bearer "+ Cookies.get('token')}})
              setFetchStatus(true)
              console.log(res)    
              navigate("/dashboard/list-job-vacancy")
            } catch (error) {
                console.log("Ada Error")
                console.log(error)
            }
          }
          createData()
        } else {
          // Update Data
          console.log('Update Data')
      
          let updateData = async () => {
            try {
              let res = await axios.put(`https://dev-example.sanbercloud.com/api/job-vacancy/${currentId}`,{title, job_description, job_qualification, job_type, job_tenure, job_status, company_name, company_image_url, company_city, salary_min, salary_max},
              {headers: {"Authorization" : "Bearer "+ Cookies.get('token')}})
              setFetchStatus(true)
              console.log(res)   
              navigate("/dashboard/list-job-vacancy") 
            } catch (error) {
                console.log("Ada Error")
                console.log(error)
            }
          }
          updateData()
        }
      
        // mengembalikan indikator ke -1
        setCurrentId(-1)
        setInput(
          {
            title : "",
            job_description : "",
            job_qualification : "",
            job_type : "",
            job_tenure : "",
            job_status : "",
            company_name : "",
            company_image_url : "",
            company_city : "",
            salary_min : "",
            salary_max : ""
          }
        )
      }
      
    const handleDelete = (event) => {
        let idData = parseInt(event.currentTarget.value)
        
        console.log(idData)
        
        axios.delete(`https://dev-example.sanbercloud.com/api/job-vacancy/${idData}`,
        {headers: {"Authorization" : "Bearer "+ Cookies.get('token')}})
        .then((res) => {
            setFetchStatus(true)
        })
    }
      
    const handleEdit = (event) => {
        let idData = parseInt(event.currentTarget.value)
        setCurrentId(idData)
        navigate(`/dashboard/form/${idData}`)
    }

    const handleChangeRegistrasi = (event) => {
      let name = event.target.name
      let value = event.target.value

      //console.log(name)
      setInput({...input, [name] : value })
  }

    const handleSubmitRegistrasi = (param) => {
        param.preventDefault()
        let {name,image_url, email, password} = input
        console.log('Handle Submit Registrasi')

        if (currentId === -1) {
          // Create Data
          console.log('Create Data Registrasi Baru')
          let createDataRegister = async () => {
            try {
              let res = await axios.post(`https://dev-example.sanbercloud.com/api/register`,{name,image_url, email, password})
              setFetchStatus(true)
              console.log(res)    
              navigate("/dashboard/list-job-vacancy")
            } catch (error) {
                console.log("Ada Error")
                console.log(error)
            }
          }
          createDataRegister()
        } 

        // mengembalikan indikator ke -1
        setCurrentId(-1)
        setInput(
          {
            name : "",
            image_url : "",
            email : "",
            password : ""
            
          }
        )
      }

    const handleChangePassword = (event) => {
        let name = event.target.name
        let value = event.target.value
  
        //console.log(name)
        setInput({...input, [name] : value })
    }

    const handleSubmitPassword = (param) => {
      param.preventDefault()
      let {current_password,new_password, new_confirm_password} = input
      /*
      console.log('Handle Submit Password ' )
      console.log(input)
      console.log('Current Password : ' )
      console.log(current_password)
      console.log('New Password : ' )
      console.log(new_password)
      console.log('Konfirmasi Password')
      console.log(new_confirm_password)
      */

      if (currentId === -1) {
        // Create Data
        console.log('Ubah Password')
        let updatePassword = async () => {
          try {
            let res = await axios.post(`https://dev-example.sanbercloud.com/api/change-password`,{current_password, new_password, new_confirm_password},
            {headers: {"Authorization" : "Bearer "+ Cookies.get('token')}})
            setFetchStatus(true)
            navigate("/dashboard")
          } catch (error) {
              console.log("Ada Error")
              console.log(error)
          }
        }
        updatePassword()
      } 

      // mengembalikan indikator ke -1
      setCurrentId(-1)
      setInput(
        {
          current_password : "",
          new_password : "",
          new_confirm_password : ""
        }
      )
    }

    let state = {
        data,
        setData,
        input,
        setInput,
        fetchStatus,
        setFetchStatus,
        currentId,
        setCurrentId
    }

    let handleFunction = {
        handleChange,
        handleChangeRegistrasi,
        handleChangePassword,
        handleSubmit,
        handleSubmitRegistrasi,
        handleSubmitPassword,
        handleDelete,
        handleEdit
    }

    

    return(
        <GlobalContext.Provider value={{ state, handleFunction }}>
            {props.children}
        </GlobalContext.Provider>
    )


   


}

