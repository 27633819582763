import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { GlobalContext } from "../../context/GlobalContext"
import { useParams } from "react-router-dom";

const FormInput = () => {

    let { idData } = useParams();
    console.log("ID Data")
    console.log( idData ) ;   

  const {state, handleFunction} = useContext(GlobalContext)

  let {
    data,
    setData,
    input,
    setInput,
    fetchStatus,
    setFetchStatus,
    currentId,
    setCurrentID
  } = state

let  {
  handleChange,
  handleSubmit,
  handleDelete,
  handleEdit
} = handleFunction

useEffect(() => {
    
    if(idData !== undefined) {
        
        axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy/${idData}`)
        .then((res) => {

            console.log("Persiapan Edit Data")
            console.log(res)
        
            let data = res.data 
            setInput(
            {
                title : data.title,
                job_description : data.job_description,
                job_qualification : data.job_qualification,
                job_type : data.job_type,
                job_tenure : data.job_tenure,
                job_status : data.job_status,
                company_name : data.company_name,
                company_image_url : data.company_image_url,
                company_city : data.company_city,
                salary_min : data.salary_min,
                salary_max : data.salary_max

            }
            )
        })
        
    } else {
        setInput(
            {
                title : "",
        job_description : "",
        job_qualification : "",
        job_type : "",
        job_tenure : "",
        job_status : "",
        company_name : "",
        company_image_url : "",
        company_city : "",
        salary_min : "",
        salary_max : ""

            })
    }
}, []);


  return (
    <>

    <div className="container mx-auto mt-10">
        <h1 className="mb-5 font-bold">FORM INPUT DATA</h1>
        <form onSubmit={handleSubmit}>
            <div className="mb-6">
            <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pekerjaan</label>
                <input onChange={handleChange} type="text" value={input.title} name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="job_description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Deskripsi</label>
                <input onChange={handleChange} type="text" value={input.job_description} name="job_description" id="job_description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="job_qualification" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Kualifikasi</label>
                <input onChange={handleChange} type="text" value={input.job_qualification} name="job_qualification" id="job_qualification" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>

            <div className="mb-6">
            <label htmlFor="job_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tipe Pekerjaan</label>
                <input onChange={handleChange} type="text" value={input.job_type} name="job_type" id="job_type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="job_tenure" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tenure</label>
                <input onChange={handleChange} type="text" value={input.job_tenure} name="job_tenure" id="job_tenure" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="job_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Status</label>
                <input onChange={handleChange} type="text" value={input.job_status} name="job_status" id="job_status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <h2 className="mb-2 font-bold">DATA PERUSAHAAN</h2>
            <div className="mb-6">
            <label htmlFor="company_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nama Perusahaan</label>
                <input onChange={handleChange} type="text" value={input.company_name} name="company_name" id="company_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="company_city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Kota</label>
                <input onChange={handleChange} type="text" value={input.company_city} name="company_city" id="company_city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="company_image_url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Logo Perusahaan</label>
                <input onChange={handleChange} type="text" value={input.company_image_url} name="company_image_url" id="company_image_url" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <h2 className="mb-2 font-bold">DATA GAJI</h2>
            <div className="mb-6">
            <label htmlFor="salary_min" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gaji Minimal</label>
                <input onChange={handleChange} type="number" value={input.salary_min} name="salary_min" id="salary_min" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-6">
            <label htmlFor="salary_max" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gaji Maksimal</label>
                <input onChange={handleChange} type="number" value={input.salary_max} name="salary_max" id="salary_max" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
    </div>

    </>
  )
}

export default FormInput