import axios from 'axios'
import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import {Accordion} from 'flowbite-react'

const LowonganPekerjaan = () => {

  // Pengambilan Data Lowongan Pekerjaan
  const [data, setData] = useState(null)

  // Indikator
  const [fetchStatus, setFetchStatus] = useState(true)
  const [display, setDisplay] = useState(false)
  

  useEffect(() => {
      let fetchData = async () => {
          let result = await axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy`)
          let data = result.data.data
          setData(data)
      }
      
      if( fetchStatus ) { 
          fetchData()
          setFetchStatus(false)
      }
      
  },[fetchStatus, setFetchStatus])

  console.log(data)

  function formatRupiah(angka, prefix){
      var number_string = angka.replace(/[^,\d]/g, '').toString(),
      split   		= number_string.split(','),
      sisa     		= split[0].length % 3,
      rupiah     		= split[0].substr(0, sisa),
      ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if(ribuan){
          let separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
      }

      rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
      return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  const handleText = (param) => {
      if(param === undefined) {
          return ""
      } else {
          return param.slice(0,200) + "..."
      }
  }

  const [search, setSearch] = useState("")
  const handleChangeSearch = (event) => setSearch(event.target.value)
  const handleSubmitSearch = (event) => {
    event.preventDefault()
    console.log(search)

    let fetchData = async () => {
      setDisplay(true)

      let result = await axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy`)
      let dataJob = result.data.data

      let searchData = dataJob.filter((res) => {
        return Object.values(res).join(" ").toLowerCase().includes(search.toLowerCase()) 
        //console.log( Object.values(res).join(" ").toLowerCase() )
      });

      setDisplay(false)
      setData([...searchData])
    }

    fetchData()
    setSearch("")
    
  }

  const [filter, setFilter] = useState({
    title : "",
    company_name : "",
    company_city : ""
  })
  const handleChangeFilter = (event) => {
    setFilter({...filter, [event.target.name] : event.target.value })
  }  
  const handleSubmitFilter = (event) => {
    event.preventDefault()
    console.log(filter)

    let fetchData = async () => {
      setDisplay(true)

      let result = await axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy`)
      let dataJob = result.data.data

      let filterData = dataJob.filter((res) => {
        return res.company_name.toLowerCase() === filter.company_name.toLowerCase() && res.company_city.toLowerCase() === filter.company_city.toLowerCase() && res.title.toLowerCase() === filter.title.toLowerCase() 
        //console.log( Object.values(res).join(" ").toLowerCase() )
      });

      setDisplay(false)
      setData([...filterData])
    }

    fetchData()
    setFilter({
      title : "",
      company_name : "",
      company_city : ""
    })
    
  }
  
  return (
    <>
    <div className="mt-10">
            <h1 className="pl-5 text-xl font-bold lg:pl-0">LOWONGAN PEKERJAAN</h1>

          <form onSubmit={handleSubmitSearch} className="flex items-center mt-10">   
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" /></svg>
              </div>
              <input onChange={handleChangeSearch} value={search} type="search" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
            </div>
            <button type="submit" className="p-2.5 w-20 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
              <span className="sr-only">Search</span>
            </button>
          </form>


<div className="mt-5">
<Accordion alwaysOpen={true}>
  
  <Accordion.Panel>
    <Accordion.Title>
    Filter Data
    </Accordion.Title>
    <Accordion.Content>
    <div className="mx-auto ">


<form onSubmit={handleSubmitFilter}>
<div className="mb-6">
    <label htmlFor="company_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nama Perusahaan</label>
        <input value={filter.company_name} onChange={handleChangeFilter} type="text"  name="company_name" id="company_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
    </div>
    <div className="mb-6">
    <label htmlFor="company_city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Kota</label>
        <input value={filter.company_city} onChange={handleChangeFilter} type="text"  name="company_city" id="company_city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
    </div>
    <div className="mb-6">
    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Pekerjaan</label>
        <input value={filter.title} onChange={handleChangeFilter} type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
    </div>
    
    
    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
</form>
</div>
    </Accordion.Content>
  </Accordion.Panel></Accordion>
  </div>
          


            
            <div className="flex flex-wrap items-start justify-center gap-10 mt-10 lg:justify-start">
            
                {
                    data !== null &&
                    data
                    .map((res) => {
                        
                    return(
   

<Link to={`/detail-data/${res.id}`} key={res.id} className="flex flex-col w-full max-w-sm p-6 overflow-hidden bg-white shadow-lg h-90 rounded-xl dark:bg-gray-800">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="flex items-center justify-start flex-grow w-full">
            <a href="#" className="relative block">
              <img alt="profil" src={res.company_image_url} className="object-cover w-10 h-10 mx-auto rounded-full " />
            </a>
            <div className="flex flex-col items-start ml-4">
              <span className="text-gray-700 dark:text-white">
                {res.company_name}
              </span>
              <span className="text-sm font-light text-gray-400 dark:text-gray-300">
                {res.company_city}
              </span>
            </div>
          </div>

        </div>
        <p className="mt-4 mb-2 text-lg text-gray-800 dark:text-white">
          {res.title}
        </p>
        <p className="text-sm font-normal text-gray-400 grow">
        {handleText(res.job_description)}
        </p>
        <div className="flex items-center justify-between p-2 my-6 bg-blue-100 rounded">
          <div className="flex items-start justify-between w-full">
            <p className="flex-grow w-full text-2xl text-gray-700">
              <span className="font-light text-gray-400 text-md">
                Rp.
              </span>
              { formatRupiah(res.salary_min + "") } 
              <span className="text-sm font-light text-gray-400">
                /Month
              </span>
            </p>
            <span className="flex-none px-3 py-1 text-sm text-indigo-500 border border-indigo-500 rounded-full">
            {res.job_tenure}
            </span>
          </div>
        </div>

      </Link>

                        )
                    })
                }    
            </div>
        </div>
    </>
  )
}

export default LowonganPekerjaan