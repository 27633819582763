import React from 'react';
import { Navbar } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Navigations = () => {

  const navigate = useNavigate()  
  const handleLogout = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    navigate('/')
  }  

  return (

    <Navbar
        fluid={false}
        rounded={true} 
        
    >

        <Navbar.Brand >
            
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            LO-KER [ Lowongan Pekerjaan ]
            </span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
        <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
                <Link to="/" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page">Beranda</Link>
            </li>
            <li>
                <Link to="/lowongan-pekerjaan" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Lowongan Pekerjaan</Link>
            </li>
            {
                !Cookies.get('token') &&
                <li>
                    <Link to="/login" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Login</Link>
                </li>
            }
            {
                Cookies.get('token') &&
                <li>
                    <Link to="/dashboard" className="block py-2 pl-3 pr-4 text-gray-700 rounded cursor-pointer hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Dashboard</Link>
                </li>
            }
            {
                Cookies.get('token') &&
                <li>
                    <span onClick={handleLogout} className="block py-2 pl-3 pr-4 text-gray-700 rounded cursor-pointer hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Logout</span>
                </li>
            }
      </ul>
        </Navbar.Collapse>
    </Navbar>

  )
}

export default Navigations