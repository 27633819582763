import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { GlobalContext } from "../../context/GlobalContext"
import { useParams } from "react-router-dom";

const GantiPassword = () => {

    let { idData } = useParams();
    console.log("ID Data")
    console.log( idData ) ;   

  const {state, handleFunction} = useContext(GlobalContext)

  let {
    data,
    setData,
    input,
    setInput,
    fetchStatus,
    setFetchStatus,
    currentId,
    setCurrentID
  } = state

let  {
  handleChangePassword,
  handleSubmitPassword
} = handleFunction

/*
useEffect(() => {
    
    if(idData !== undefined) {
        
        axios.get(`https://dev-example.sanbercloud.com/api/change-password/${idData}`)
        .then((res) => {

            console.log("Persiapan ganti password")
            console.log(res)
        
            let data = res.data 
            setInput(
            {
                current_password : "",
                new_password : "",
                new_confirm_password : ""
            }
            )
        })
        
    } else {
        setInput(
            {
                current_password : "",
                new_password : "",
                new_confirm_password : ""

            })
    }
}, []);
*/
/*
useEffect(() => {
setInput(
    {
        current_password : "",
        new_password : "",
        new_confirm_password : ""

    })
})
*/

  return (
    <>

    <div className="container mx-auto mt-10">
        <h1 className="mb-5 font-bold">GANTI PASSWORD</h1>
        <form onSubmit={handleSubmitPassword}>
            <div className="mb-6">
                <label htmlFor="current_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password Lama</label>
                <input onChange={handleChangePassword} type="text" value={input.current_password} name="current_password" id="current_password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            
            <h2 className="mb-2 font-bold">PASSWORD BARU</h2>
            
            <div className="mb-6">
                <label htmlFor="new_password " className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password Baru</label>
                <input onChange={handleChangePassword} type="text" value={input.new_password } name="new_password" id="new_password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            
            <div className="mb-6">
                <label htmlFor="new_confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Konfirmasi Password</label>
                <input onChange={handleChangePassword} type="text" value={input.new_confirm_password} name="new_confirm_password" id="new_confirm_password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
    </div>

    </>
  )
}

export default GantiPassword